<template>
<div class="admin-container" v-loading.fullscreen.lock="loading">
    <div class="admin-container-header">
        <div class="flex justify-between">
            <h1>Files</h1>
            <div class="search-input">
                <input placeholder="Search..." v-on:keyup.enter="SearchFun"   v-model="search" class="input-with-select "  :class="serchEror?'serchEror':''"   /> 
                <el-button icon="el-icon-search" circle @click="SearchFun"></el-button>
            </div>
        </div>
    </div>
    <div class="admin-table">
        <table class="admin-table-table" v-if="Files.length>0">
            <tr>
                <th>URL</th>
                <td style="width:410px; text-align:right;">Дії</td>
            </tr>
            <tr v-for="(item, i) in Files" :key="i">
                <td> {{item.name}}</td>
                <td style="text-align:right;">
                    <button @click="Copy(item.path)" slot="reference">Copy</button> 
                    <el-popconfirm class="ml-10" @confirm="_DELETE(item.id)" confirm-button-text='OK' cancel-button-text='No, Thanks' icon="el-icon-info" icon-color="red" title="Are you sure to delete this?">
                        <el-button class="delete" style="background:red;" slot="reference">Delete</el-button>
                    </el-popconfirm> </td>
            </tr>
        </table>
        <div class="no-data" v-else>
            <p>
                File not found!
            </p>
        </div>
        <table class="admin-table-table">
            <tr>
                <td>
                    <el-input v-if="file.name" v-model="fileName" type="text" class="w-full" />
                    <p v-else>...</p>
                    <p v-if="errorFile" style="color:red;">Upload File</p>
                </td>
                <td style="width:410px; text-align:right;">
                    <div class="file-upload-buttons">
                        <button class="file-upload-open"><input type="file" @change="UploadFile"> Upload File </button>
                        <button class="file-upload-save" :disabled="disabled" :class="disabled?'':'active'" @click="SaveFile">
                            <span v-if="!fileLoad">Save</span>
                            <span v-else> <i class="el-icon-loading"></i> </span>
                        </button>
                    </div>
                    <p v-if="errorFile" style="color:red; text-align:right">Upload File</p>
                </td>
            </tr>
        </table>
        <div class="admin-poginations">
            <el-pagination     @current-change="paginate" :current-page.sync="current_page" :hide-on-single-page="false" background layout=" prev, pager, next" :page-count="page_count">
            </el-pagination>
        </div>

    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            current_page: Number(1),
            page_count: 1,
            page_size: 10,
            loading: false,
            search: '',
            file: '',
            disabled: true,
            fileLoad: false,
            Files: [],
            test: [],
            fileName: '',
            errorFile: false,
            serchEror:false
        }

    },
    watch:{
        search(val){
             if(val==''){
                 this.Get()
             }
        }
    },
    methods: {
        Get() {
            this.loading = true
            if (this.$route.query.page != this.current_page) {
                this.$router.push(`/admin/files?page=${this.current_page}`)
            }
            this.axios.get(`/admin/file?page=${this.current_page}`)
                .then(res => {
                    this.page_count = parseInt(res.data.data.total / this.page_size)
                    if (res.data.data.total % this.page_size > 0) {
                        this.page_count = this.page_count + 1
                    }
                    this.current_page = this.$route.query.page ? Number(this.$route.query.page) : Number(1)
                    this.Files = res.data.data.data

                })
                .finally(() => {
                    this.loading = false
                })
        },
        SearchFun() {
            if(this.search!=''){
                this.loading = true
                this.axios.post(`/admin/file/get`, {
                    name: this.search
                })
                .then(res => {
                    this.page_count = Number(1)
                    this.current_page = Number(1)
                    this.current_page = this.$route.query.page ? Number(this.$route.query.page) : Number(1)
                    this.Files = res.data.data

                })
                .finally(() => {
                    this.loading = false
                })
            }
            else{
                this.Get()
            }

        },
        UploadFile(event) {
            this.file = event.target.files[0]
            this.fileName = event.target.files[0].name
            this.disabled = false
            this.errorFile = false

        },
        SaveFile() {
            if (this.fileName != '' && this.file != '') {
                let data = new FormData();
                data.append('file', this.file)
                data.append('name', this.fileName)
                this.fileLoad = true
                this.disabled = true
                this.axios.post('/admin/file/upload', data)
                    .then(res => {
                        var link = `https://avior.prestigeautofl.com${res.data.data.path}`
                        this.Files.push(link)
                        this.file = ''
                        this.fileName = ''
                        this.fileLoad = false
                    })
                    .catch(() => {
                        this.fileLoad = false
                    })
            } else {
                this.errorFile = true
            }
        },
        Copy(item) {
            var a = this.$copyText(`https://avior.prestigeautofl.com${item}`)
            a ? this.$notify({
                title: 'Link Copied',
                type: 'success'
            }) : this.$notify({
                title: 'Not Copied',
                type: 'success'
            })
        },
        paginateSize() {
            console.log("AAA")
        },
        paginate(val) {
            this.current_page = Number(val);
            this.Get()
        },
        _DELETE(i) {
            this.loading = true
            this.axios.delete(`/admin/file/delete/${i}`)
                .then(() => {
                     this.$notify({
                                title: 'Delete',
                                message: 'Ви успішно ввійшли',
                                type: 'success'
                            });
                    this.Get()
                })
                .catch(error => { 
                            let ERRORS = error.response.data.message;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            this.$notify.error({
                                 title: 'Произошла ошибка',
                                message: error_msg ? error_msg : "Произошла ошибка",
                            });

                        })
                .finally(() => {
                    this.loading = false
                })
        },

    },
    computed: {
        Pager() {
            return this.$store.state.Home
        }
    },
    mounted() {
        this.current_page = this.$route.query.page ? Number(this.$route.query.page) : Number(1)
        this.Get()
    }
}
</script>

<style lang="scss">
.file-upload-buttons {
    display: flex;
    align-items: center;

    .file-upload-open {
        position: relative;
        width: 200px;
        margin-right: 10px;

        input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }

    .file-upload-save {
        width: 200px;
        background: #409EFF;
        opacity: 0.5;

        &.active {
            opacity: 1;
        }
    }
}
.serchEror{
   .el-input__inner, .el-input-group__append{
        border-color: red !important;
        
   }
  
}
.search-input{
    display: flex;
    align-items: center;
    input{
        padding: 10px;
        outline: none;
        border: 1px solid #DCDFE6;
        border-radius: 5px;
        margin-right: 5px;
        width: 400px;

    }

}
.no-data{
    width: 100%;
    padding: 50px 20px;
    text-align: center;
    color: red;

}

.admin-container {
    background: var(--white-bg);
    min-height: 100%;
    width: 100%;
    

}

.admin-container-header {
    padding: 20px;
}

.admin-table {
    width: 100%;
}

.admin-table-table {
    width: 100%;
    border-collapse: collapse;

    th {
        text-align: left;
        padding: 10px;
        border-collapse: collapse;
        font-family: Stem-Medium;
    }

    td {
        padding: 10px;
        border-collapse: collapse;
    }

    tr {
        border-bottom: 1px solid #EBEEF5;
        border-collapse: collapse;
        font-family: Stem-Regular;
    }

    button {
        border: none;
        font-family: Stem-Medium;
        padding: 8px 16px;
        color: var(--white-color);
        border: none;
        background: #44405C;
        cursor: pointer;
        outline: none;
        border-radius: 4px;

    }
}

.admin-poginations {
    padding: 20px 10px 10px 10px;
    display: flex;
    justify-content: flex-end;
}
</style>
